<template>
    <front-layout>
        <div class="FaqArticle intro wrapper">
            <v-breadcrumbs :items="breadcrumb"/>

            <h1 v-text="'Submit a request'" class="text-center mb-5"/>

            <v-row class="justify-center">
                <v-col md="8">
                    <v-card class="mb-5">
                        <v-card-text>
                            <v-text-field label="Your email address" v-model="request.email"/>
                            <v-text-field label="Subject" v-model="request.subject"/>
                            <v-textarea auto-grow label="Description" v-model="request.description"/>
                            <p>Please enter the details of your request. A member of our support staff will respond as soon as possible.</p>
                            <v-file-input label="Attachments" v-model="request.file" truncate-length="15"/>
                            <v-btn @click="submit" v-text="'Submit'"/>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </front-layout>
</template>

<script>
    import FrontLayout from "../../layouts/FrontLayout";
    import Faq from "../../api/Faq";

    export default {
        name: "FaqNewRequest",
        components: {FrontLayout},
        data() {
            return {
                request: {},
                breadcrumb: [
                    {
                        text: 'Home',
                        disabled: false,
                        href: this.$router.resolve({name: 'home'}).href,
                    },
                    {
                        text: 'Help',
                        disabled: false,
                        href: this.$router.resolve({name: 'faq'}).href,
                    },
                    {
                        text: 'Submit a request',
                        disabled: true,
                    }
                ],
            }
        },
        methods: {
            submit() {
                Faq.createNewRequest(this.request);
            }
        },
    }
</script>

<style lang="scss" scoped>
.FaqArticle {

}
</style>
