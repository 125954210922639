export default {
    async createNewRequest(data = {}) {
        let formData = new FormData();

        for (let key in data) {
            formData.append(key, data[key]);
        }

        const response = await window.axios({
            method: 'post',
            url: '/api/faq/requests/new',
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })

        return response.data;
    }
}
